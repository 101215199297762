import { memo } from 'react';
import {BookContainer} from './styles';

const BookText = memo(({ bookText}: { bookText?: string }) => <div
id="book-text"
dangerouslySetInnerHTML={{
  __html:
    bookText ??
    '<div style="display:flex;justify-content:space-around;margin-top:80px;width:100%;"><div>♡</div></div>',
}}
/>, ({ bookText }) => bookText !== undefined);

const Book = ({
    bookText,
    controlsHiddenMobile,
    isBookmarking,
    isWide,
    onClick,
}: {
    bookText?: string;
    controlsHiddenMobile: boolean;
    isBookmarking: boolean;
    isWide: boolean;
    onClick: (event: any) => void;
}) => {
 return <BookContainer 
          controlsHiddenMobile={controlsHiddenMobile}
          id="book-text-container"
          isBookmarking={isBookmarking}
          onClick={onClick}
          isWide={isWide}
        >
          <BookText bookText={bookText}
          />
        </BookContainer>;
};

export default Book;

