import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { parseEther } from "viem";
import {
  useAccount,
  useBalance,
  useChainId,
  useReadContracts,
  useTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { arbitrum, sepolia } from "wagmi/chains";
import Book from './book';
import {
  API_URL,
  ARBISCAN_URL,
  CONTRACT_ADDRESS,
  DEV_API_URL,
  ERRORS,
  GRAY,
  MINT_PRICE,
  PDF_FILE_NAME,
  SEPOLIA_CONTRACT_ADDRESS,
  SEPOLIA_ETHERSCAN_URL,
} from "./constants";
import Link from "./link";
import rightToTransactAbi from "./abi/rightToTransactAbi.json";
import {
  Button,
  ControlButtons,
  DesktopControls,
  Errors,
  Flex,
  Info,
  MintContainer,
  MintOuterContainer,
  MobileControls,
  Mono,
  PseudoLink,
  ShowControlsContainer,
  StyledError,
} from "./styles";
import { AboutSection, Environment } from "./types";
import Layout from "./layout";

const CONFIG = {
  [Environment.Dev]: {
    apiUrl: DEV_API_URL,
    blockExplorerUrl: SEPOLIA_ETHERSCAN_URL,
    contractAddressUntyped: SEPOLIA_CONTRACT_ADDRESS,
    correctChainId: sepolia.id,
  },
  [Environment.Prod]: {
    apiUrl: API_URL,
    blockExplorerUrl: ARBISCAN_URL,
    contractAddressUntyped: CONTRACT_ADDRESS,
    correctChainId: arbitrum.id,
  },
};

const { REACT_APP_ENVIRONMENT } = process.env;

const { apiUrl, blockExplorerUrl, contractAddressUntyped, correctChainId } =
  CONFIG[(REACT_APP_ENVIRONMENT as Environment) ?? Environment.Prod];

// wagmi's a pretty annoying library tbh
const abi = JSON.parse(JSON.stringify(rightToTransactAbi));
const contractAddress = contractAddressUntyped as `0x${string}`;

const App = () => {
  const [aboutSection, setAboutSection] = useState<AboutSection | null>(null);
  const [bookmarkLocation, setBookmarkLocation] = useState<string | null>(null);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [hideControls, setHideControls] = useState<boolean>(false);
  const [info, setInfo] = useState<string | null>(null);
  const [isBookmarking, setIsBookmarking] = useState<boolean>(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false);
  const [isMinting, setIsMinting] = useState<boolean>(false);
  const [jankyRouterIsReady, setJankyRouterIsReady] = useState<boolean>(false);
  const [mintSuccess, setMintSuccess] = useState<boolean>(false);
  const [shouldHideMobileControls, setShouldHideMobileControls] =
    useState<boolean>(false);
  const [shouldScroll, setShouldScroll] = useState<boolean>(true);
  const [waiting, setWaiting] = useState<boolean>(true);

  const queryClient = useQueryClient();

  const { address: addressRaw } = useAccount();
  const address = addressRaw as `0x${string}`;

  const {
    data: balance,
    error: balanceError,
    isFetching: balanceLoading,
  } = useBalance({
    address,
    chainId: correctChainId,
  });

  const chainId = useChainId();

  const addError = useCallback(
    (error: string) => {
      const index = errors.indexOf(error);
      if (index !== -1) return;

      setErrors([...errors, error]);
    },
    [errors, setErrors],
  );

  const { data: hash, writeContract } = useWriteContract();

  const { data: receipt } = useTransactionReceipt({
    hash,
    chainId: correctChainId,
  });

  const {
    data: balanceOfRaw,
    error: balanceOfError,
    isFetching: balanceOfLoading,
    queryKey: balanceOfQueryKey,
  } = useReadContracts({
    contracts: [
      {
        abi,
        address: contractAddress,
        args: [address],
        chainId: correctChainId,
        functionName: "balanceOf",
      },
    ],
    query: {
      enabled: !!address,
      refetchOnMount: "always",
    },
  });

  const balanceOf = useMemo(
    () => Number(balanceOfRaw?.[0]?.result?.toString() ?? "0"),
    [balanceOfRaw],
  );

  // TODO: clean this up
  const hasSufficientBalanceToMint = useMemo(() => {
    if (
      !address ||
      balanceError ||
      balanceLoading ||
      balance === undefined ||
      balanceOfError ||
      balanceOfLoading
    )
      return null;
    return Number(balance.value?.toString() ?? "0") > MINT_PRICE || !!balanceOf;
  }, [
    address,
    balance,
    balanceError,
    balanceLoading,
    balanceOf,
    balanceOfError,
    balanceOfLoading,
  ]);

  useEffect(() => {
    if (receipt) setIsMinting(false);

    if (receipt?.status === "success") {
      setMintSuccess(true);
      queryClient.invalidateQueries({ queryKey: balanceOfQueryKey });
    }
  }, [receipt]);

  const {
    data: bookTextRaw,
    // error: bookTextError,
    // isFetching: bookTextLoading,
  } = useReadContracts({
    contracts: Array.from("💰".repeat(32)).map((_, i) => ({
      abi,
      address: contractAddress,
      args: [`${i}`],
      chainId: correctChainId,
      functionName: "read",
    })),
    query: {
      enabled: !!balanceOf,
    },
  });

  const bookText = useMemo(
    () => bookTextRaw?.reduce((acc, cur) => `${acc}${cur.result ?? ""}`, ""),
    [bookTextRaw],
  );

  useEffect(() => {
    if (!balanceOfError?.message) return;
    addError(balanceOfError.message);
  }, [balanceOfError]);

  const handleBookmarkClick = useCallback(
    (event: any) => {
      if (!isBookmarking) return;

      const tagName = event.target?.tagName?.toLowerCase() ?? "";

      if (["h1", "h2", "h3", "h4", "p"].includes(tagName)) {
        const paragraphSnippet = event.target.innerText?.slice(0, 50) ?? "";

        if (paragraphSnippet) {
          setBookmarkLocation(paragraphSnippet);
          setIsBookmarking(false);
          event.target.classList?.add("glow");

          setTimeout(() => {
            event.target.classList?.remove("glow");
          }, 2000);
        }
      } else {
        const parentTagName =
          event.target?.parentNode?.tagName?.toLowerCase() ?? "";

        if (["h1", "h2", "h3", "h4", "p"].includes(parentTagName)) {
          const paragraphSnippet =
            event.target.parentNode.innerText?.slice(0, 50) ?? "";

          if (paragraphSnippet) {
            setBookmarkLocation(paragraphSnippet);
            setIsBookmarking(false);
            event.target.parentNode.classList?.add("glow");

            setTimeout(() => {
              event.target.parentNode.classList?.remove("glow");
            }, 2000);
          }
        }
      }
    },
    [isBookmarking, setBookmarkLocation, setIsBookmarking],
  );

  const handleDownloadPdfClick = useCallback(() => {
    setIsDownloadingPdf(true);

    fetch(`${apiUrl}/pdf`, {
      method: "POST",
    })
      .then((res) => res.blob())
      .then(URL.createObjectURL)
      .then((href) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = href;
        a.download = PDF_FILE_NAME;
        a.click();
      })
      .catch((err) => {
        if (err.message) {
          setErrors([...errors, err.message]);
        }
      })
      .finally(() => setIsDownloadingPdf(false));
  }, [setErrors, setIsDownloadingPdf]);

  const handleMintClick = useCallback(() => {
    setIsMinting(true);

    writeContract(
      {
        abi,
        account: address,
        address: contractAddress,
        args: [address, 1n],
        chainId: correctChainId,
        functionName: "mint",
        value: parseEther("0.008"),
      },
      {
        onError: (err) => {
          setIsMinting(false);

          if (err?.message) {
            setErrors([...errors, err.message]);
          }
        },
      },
    );
  }, [address, errors, setErrors, setIsMinting]);

  const removeError = useCallback(
    (error: string) => {
      const index = errors.indexOf(error);
      if (index === -1) return;

      const newErrors = [...errors.slice(0, index), ...errors.slice(index + 1)];
      setErrors(newErrors);
    },
    [errors, setErrors],
  );

  const scrollToBookmarkLocation = useCallback((temporaryBookmarkLocation?: string, shouldGlow = true) => {
    const location = temporaryBookmarkLocation || bookmarkLocation;
    if (!location) return;
    const bookmarkedNode =
      Array.from(document.querySelectorAll("p"))?.find((node) => {
        return node.textContent?.slice(0, 50) === location;
      }) ??
      [
        ...Array.from(document.querySelectorAll("h1")),
        ...Array.from(document.querySelectorAll("h2")),
        ...Array.from(document.querySelectorAll("h3")),
        ...Array.from(document.querySelectorAll("h4")),
      ].find((node) => {
        return node.textContent?.slice(0, 50) === location;
      });
    bookmarkedNode?.scrollIntoView({ behavior: "smooth" });
    if (shouldGlow) {
      bookmarkedNode?.classList?.add("glow");
      setTimeout(() => {
        bookmarkedNode?.classList?.remove("glow");
      }, 2000);
    }
  }, [bookmarkLocation]);

  const handleToggleMobileControls = useCallback((shouldHide: boolean) => {
    let paragraphText: string | undefined = undefined;
    const bookTextContainer = document.querySelector('#book-text-container');
    if (!bookTextContainer) return;
    const paragraphs = bookTextContainer.querySelectorAll('p');
    for (const p of paragraphs) {
      if (p.offsetTop > bookTextContainer.scrollTop) {
        paragraphText = p.innerHTML?.slice(0, 50);
        break;
      }
    }
    setShouldHideMobileControls(shouldHide);
    setTimeout(() => scrollToBookmarkLocation(paragraphText, false), 0);
  }, [setShouldHideMobileControls, scrollToBookmarkLocation]);

  // on mount
  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 800);

    // get bookmark location from localstorage
    const persistedBookmarkLocation = localStorage.getItem("bookmarkLocation");
    if (persistedBookmarkLocation) {
      setBookmarkLocation(persistedBookmarkLocation);
    }

    // get about section from url
    const queryParams = new URLSearchParams(window.location.search);
    const aboutParam = queryParams.get("about");

    if (aboutParam === "book") {
      setAboutSection(AboutSection.Book);
    } else if (aboutParam === "mint") {
      setAboutSection(AboutSection.Mint);
    } else if (aboutParam === "author") {
      setAboutSection(AboutSection.Author);
    } else {
      setAboutSection(null);
    }

    setJankyRouterIsReady(true);
  }, []);

  // update url to reflect about section
  useEffect(() => {
    if (!jankyRouterIsReady) return;
    if (!aboutSection) {
      window.history.pushState(null, "", window.location.pathname);
      return;
    }

    const queryParams = new URLSearchParams();
    const aboutParam =
      aboutSection === AboutSection.Book
        ? "book"
        : aboutSection === AboutSection.Mint
        ? "mint"
        : aboutSection === AboutSection.Author
        ? "author"
        : undefined;

    if (!aboutParam) return;
    queryParams.set("about", aboutParam);

    window.history.pushState(
      null,
      "",
      `${window.location.pathname}?${queryParams.toString()}`,
    );
  }, [aboutSection, jankyRouterIsReady]);

  // save bookmark location to localstorage
  useEffect(() => {
    if (!bookmarkLocation) return;
    const persistedBookmarkLocation = localStorage.getItem("bookmarkLocation");
    if (bookmarkLocation === persistedBookmarkLocation) return;
    localStorage.setItem("bookmarkLocation", bookmarkLocation);
  }, [bookmarkLocation]);

  // scroll to bookmark location on initial load
  useEffect(() => {
    if (!shouldScroll || !bookmarkLocation || !bookText || waiting) return;
    scrollToBookmarkLocation();
    setShouldScroll(false);
  }, [bookmarkLocation, bookText, shouldScroll, waiting]);

  // show error if not on correct chain
  useEffect(() => {
    if (!chainId) {
      return;
    } else if (chainId !== correctChainId) {
      addError(ERRORS.switchChain);
    } else {
      removeError(ERRORS.switchChain);
    }
  }, [chainId]);

  // add or remove insufficient funds error
  useEffect(() => {
    (hasSufficientBalanceToMint || hasSufficientBalanceToMint === null
      ? removeError
      : addError)("You don't have enough eth to mint :(");
  }, [hasSufficientBalanceToMint]);

  // don't keep showing the bookmarking ux explanation
  useEffect(() => {
    if (!isBookmarking) setInfo(null);
    if (isBookmarking && !localStorage.getItem("knowsAboutBookmarking")) {
      // this causes the parent to re-render, scrolling the book to the top
      // which utterly defeats the point of bookmarking
      // setInfo("Click on a paragraph in the text to bookmark your spot");
      localStorage.setItem("knowsAboutBookmarking", "true");
    }
  }, [isBookmarking]);

  // TODO: move into context
  const LayoutWithProps = useMemo(
    () =>
      ({ children }: any) => {
        return (
          <Layout
            aboutSection={aboutSection}
            blockExplorerUrl={blockExplorerUrl}
            errors={errors}
            handleToggleMobileControls={handleToggleMobileControls}
            info={info}
            mintSuccess={mintSuccess}
            setAboutSection={setAboutSection}
            setErrors={setErrors}
            setInfo={setInfo}
            setMintSuccess={setMintSuccess}
            shouldHideMobileControls={shouldHideMobileControls}
          >
            {children}
          </Layout>
        );
      },
    [
      aboutSection,
      blockExplorerUrl,
      errors,
      info,
      mintSuccess,
      setAboutSection,
      setErrors,
      setInfo,
      setMintSuccess,
      setShouldHideMobileControls,
      shouldHideMobileControls,
    ],
  );

  if (waiting)
    return (
      <LayoutWithProps>
        <Flex
          gridColumn="1/-1"
          justifyContent="space-around"
          marginTop="68px"
          width="100%"
        >
          ♡
        </Flex>
      </LayoutWithProps>
    );

  if (errors.includes(ERRORS.switchChain)) {
    return (
      <LayoutWithProps>
        <Flex
          flexDirection="column"
          gap="24px"
          gridColumn="1/-1"
          paddingTop="32px"
        >
          <StyledError
            style={{
              gridColumn: "1/-1",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            {ERRORS.switchChain}
          </StyledError>
          <p>
            Or learn why you might want to{" "}
            <PseudoLink onClick={() => setAboutSection(AboutSection.Mint)}>
              here
            </PseudoLink>
            .
          </p>
        </Flex>
      </LayoutWithProps>
    );
  }

  if (address && balanceOf) {
    return (
      <LayoutWithProps>
        {hideControls ? (
          <ShowControlsContainer>
            <Button
              onClick={() => setHideControls(false)}
              width="auto"
              style={{
                border: "none",
                fontSize: "12px",
                height: "26px",
                padding: 0,
              }}
            >
              {"<"} Show sidebar
            </Button>
          </ShowControlsContainer>
        ) : (
          <DesktopControls onClick={() => setIsBookmarking?.(false)}>
            <ControlButtons>
              <Button
                isActive={isBookmarking}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsBookmarking(!isBookmarking);
                }}
              >
                Bookmark your spot
              </Button>
              <Button
                disabled={!bookmarkLocation}
                onClick={bookmarkLocation ? () => scrollToBookmarkLocation() : () => {}}
              >
                Scroll to bookmark
              </Button>
              <Button
                disabled={isDownloadingPdf}
                onClick={handleDownloadPdfClick}
              >
                Download PDF
              </Button>
              <Link
                href={`${blockExplorerUrl}/address/${contractAddress}#code`}
                noUnderline
              >
                <Button>Contract</Button>
              </Link>
              <Button onClick={() => setAboutSection(AboutSection.Book)}>
                About
              </Button>
              {info && <Info>{info}</Info>}
            </ControlButtons>

            <ControlButtons>
              <Errors>
                {errors.map((error, index) => (
                  <StyledError key={`${error}-${index}`}>{error}</StyledError>
                ))}
                {errors.length > 0 && (
                  <Button onClick={() => setErrors([])}>Clear errors</Button>
                )}
              </Errors>
              <Button onClick={() => setHideControls(true)}>
                Hide sidebar
              </Button>
            </ControlButtons>
          </DesktopControls>
        )}
        {!shouldHideMobileControls && (
          <MobileControls
            onClick={() => {
              setIsBookmarking?.(false);
            }}
          >
            <Button
              isActive={isBookmarking}
              onClick={(e) => {
                e.stopPropagation();
                setIsBookmarking(!isBookmarking);
              }}
            >
              Bookmark your spot
            </Button>
            <Button
              disabled={!bookmarkLocation}
              onClick={bookmarkLocation ? () => scrollToBookmarkLocation() : () => {}}
            >
              Scroll to bookmark
            </Button>
            <Button
              disabled={isDownloadingPdf}
              onClick={handleDownloadPdfClick}
            >
              Download PDF
            </Button>
            <Link
              href={`${blockExplorerUrl}/address/${contractAddress}#code`}
              noUnderline
            >
              <Button>Contract</Button>
            </Link>
            <Button onClick={() => setAboutSection(AboutSection.Book)}>
              About
            </Button>
            <Button onClick={() => handleToggleMobileControls(true)}>
              Hide
            </Button>
          </MobileControls>
        )}
        <Book
          bookText={bookText}
          controlsHiddenMobile={shouldHideMobileControls}
          isBookmarking={isBookmarking}
          onClick={handleBookmarkClick}
          isWide={hideControls}
        />
      </LayoutWithProps>
    );
  }

  if (address && !balanceOf && !balanceOfLoading) {
    return (
      <LayoutWithProps>
        <MintOuterContainer>
          <MintContainer>
            <h2
              style={{
                color: "skyblue",
                fontFamily: "monospace",
                margin: 0,
                textAlign: "center",
              }}
            >
              Mint <em>The Right to Transact</em>
            </h2>
            <p style={{ textAlign: "center" }}>
              The whole damn book is on-chain. Mint to read it here or Wherever
              Arbitrum Blockchain Data Is Read. (Also you can download a PDF.)
              More details{" "}
              <PseudoLink onClick={() => setAboutSection(AboutSection.Mint)}>
                here
              </PseudoLink>
              .
            </p>
            {!!errors.length && (
              <Errors style={{ alignItems: "center", marginBottom: "32px" }}>
                {errors.map((error, index) => (
                  <StyledError key={`${error}-${index}`}>{error}</StyledError>
                ))}
                <Button
                  onClick={() => setErrors([])}
                  width="180px"
                  style={{ marginTop: "12px" }}
                >
                  Clear errors
                </Button>
              </Errors>
            )}
            <Mono>Price: 0.008Ξ</Mono>
            <Mono style={{ marginBottom: "12px" }}>
              {`Eth balance: ${balance?.formatted ?? 0}`}Ξ
            </Mono>
            {isMinting ? (
              <Button disabled width="180px">
                Minting ...
              </Button>
            ) : (
              <Button
                disabled={!hasSufficientBalanceToMint}
                onClick={handleMintClick}
                width="180px"
              >
                Mint!
              </Button>
            )}
            {!errors.length && !hasSufficientBalanceToMint && (
              <p style={{ color: GRAY, marginTop: "12px" }}>
                You need at least 0.008Ξ to mint
              </p>
            )}
          </MintContainer>
        </MintOuterContainer>
      </LayoutWithProps>
    );
  }

  return (
    <LayoutWithProps>
      <Flex
        flexDirection="column"
        gap="24px"
        gridColumn="1/-1"
        paddingTop="32px"
      >
        <p>
          Connect your wallet (Arbitrum network) or read about why you might
          want to:
        </p>
        <Flex gap="18px">
          <ConnectButton.Custom>
            {({ openConnectModal }) => (
              <Button onClick={openConnectModal} width="180px">
                Connect
              </Button>
            )}
          </ConnectButton.Custom>
          <Button
            onClick={() => setAboutSection(AboutSection.Book)}
            width="180px"
          >
            About
          </Button>
        </Flex>
      </Flex>
    </LayoutWithProps>
  );
};

export default App;
